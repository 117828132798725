import { Flex, Icon, Text, useColorModeValue } from '@chakra-ui/react'
import { VscFeedback } from 'react-icons/vsc'

export function SendFeedbackButton(): JSX.Element {
  const cardBgColor = useColorModeValue('white', 'gray.700')
  const iconBgColor = useColorModeValue('gray.200', 'gray.900')
  const borderColor = useColorModeValue('gray.300', 'gray.700')

  return (
    <Flex
      justifyContent="end"
      position="fixed"
      bottom="5"
      right="5"
      bg={cardBgColor}
      py={2}
      px={2}
      cursor="pointer"
      borderRadius="md"
      border="1px solid"
      borderColor={borderColor}
    >
      <Flex
        data-tally-open="w8MYer"
        data-tally-align-left="1"
        data-tally-hide-title="1"
        data-tally-overlay="1"
        data-tally-emoji-animation="wave"
        type="button"
        variant="transparent"
        alignItems="center"
        overflow="hidden"
        width="fit-content"
        transition="all 0.3s ease"
        _hover={{
          width: 'auto',
          '& > .hover-text': {
            maxWidth: '200px',
            opacity: 1,
            marginLeft: '8px',
          },
        }}
        cursor="pointer"
      >
        <Icon fontSize="4xl" as={VscFeedback} bg={iconBgColor} p={2} borderRadius="md" />
        <Text
          className="hover-text"
          maxWidth="0"
          opacity="0"
          whiteSpace="nowrap"
          overflow="hidden"
          transition="all 0.3s ease"
          marginLeft="0"
        >
          Enviar feedback
        </Text>
      </Flex>
    </Flex>
  )
}
