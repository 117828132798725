interface Translations {
  [key: string]: string
}

const pt: Translations = {
  super: 'Super',
  admin: 'Administrador',
  supervisor: 'Supervisor',
  risk_analyst: 'Analista de riscos',
  financial_accounts_receivable: 'Analista financeiro - Contas a receber',
  financial_accounts_payable: 'Analista financeiro - Contas a pagar',
  logistics_analyst: 'Analista de logística',
  commercial_analyst: 'Analista comercial',
  seller: 'Executivo de vendas',
  monitoring_assistant: 'Assistente de monitoramento',
  motorist: 'Motorista',

  people: 'Pessoas',
  authorization: 'Autorização',
  operation: 'Operação',
  financial: 'Financeiro',
  account: 'Conta',
  authentication: 'Autenticação',
  territorial: 'Territorial',

  'get-permissions-by-roles': 'obter permissões por cargo',
  'list-actions': 'listar ações',
  'update-permissions-by-roles': 'atualizar permissões por cargo',
  'create-black-list': 'criar lista negra',
  'delete-black-list': 'excluir lista negra',
  'find-black-list': 'encontrar lista negra',
  'list-black-list': 'listar lista negra',
  'update-black-list': 'atualizar lista negra',
  'block-entities-validator': 'validador de entidades bloqueadas',
  'create-blocked-entities': 'criar entidades bloqueadas',
  'delete-blocked-entities': 'excluir entidades bloqueadas',
  'find-blocked-entities': 'encontrar entidades bloqueadas',
  'create-client-contacts': 'criar contatos de clientes',
  'delete-client-contacts-by-id': 'excluir contatos de clientes por ID',
  'find-client-contacts': 'encontrar contatos de clientes',
  'find-client-contacts-by-id': 'encontrar contatos de clientes por ID',
  'list-client-contacts': 'listar contatos de clientes',
  'update-client-contacts': 'atualizar contatos de clientes',
  'create-client': 'criar cliente',
  'delete-client-by-id': 'excluir cliente por ID',
  'find-client-by-cnpj': 'encontrar cliente por CNPJ',
  'find-client-by-email': 'encontrar cliente por email',
  'find-client-by-id': 'encontrar cliente por ID',
  'list-client': 'listar clientes',
  'search-clients-by-name': 'procurar clientes por nome',
  'update-client': 'atualizar cliente',
  'create-driver-license': 'criar carteira de motorista',
  'get-drivers-license-informations': 'obter informações da carteira de motorista',
  'update-driver-license': 'atualizar carteira de motorista',
  'route-planner': 'planejador de rotas',
  'create-freight-tracker-devices': 'criar dispositivos de rastreamento de frete',
  'delete-freight-tracker-devices': 'excluir dispositivos de rastreamento de frete',
  'list-freight-tracker-devices': 'listar dispositivos de rastreamento de frete',
  'find-place': 'encontrar local',
  'get-position-by-license-plate': 'obter posição pela placa',
  'request-geolocation-by-whatsapp': 'solicitar geolocalização por WhatsApp',
  'save-position-by-webhook': 'salvar posição por webhook',
  'sending-initial-monitoring-whats-app': 'enviar monitoramento inicial pelo WhatsApp',
  'get-document-by-protocol-number': 'obter documento pelo número de protocolo',
  'send-document-to-idwall': 'enviar documento para IDwall',
  'create-manual-monitoring': 'criar monitoramento manual',
  'list-manual-monitoring': 'listar monitoramento manual',
  'create-sessions': 'criar sessões',
  'attach-vehicle': 'anexar veículo',
  'check-motorist-offline-and-notify': 'verificar motorista offline e notificar',
  'create-motorist': 'criar motorista',
  'create-motorist-attachment': 'criar anexo de motorista',
  'create-motorist-whatsapp-json-file': 'criar arquivo JSON de WhatsApp de motorista',
  'delete-motorist-attachments': 'excluir anexos de motorista',
  'delete-motorist-by-id': 'excluir motorista por ID',
  'detach-vehicle': 'desanexar veículo',
  'find-motorist-app-infos': 'encontrar informações do app do motorista',
  'find-motorist-by-cpf': 'encontrar motorista por CPF',
  'find-motorist-by-id': 'encontrar motorista por ID',
  'find-motorist-by-phone': 'encontrar motorista por telefone',
  'list-motorist-device-infos': 'listar informações do dispositivo do motorista',
  'list-motorists': 'listar motoristas',
  'motorist-checkin-notifications': 'notificações de check-in do motorista',
  'create-motorist-contacts': 'criar contatos de motorista',
  'count-created-by-operator': 'contar criados pelo operador',
  'restore-motorist-by-id': 'restaurar motorista por ID',
  'search-motorist': 'procurar motorista',
  'reactivation-by-fcm': 'reativação por FCM',
  'reactivation-by-whatsapp': 'reativação por WhatsApp',
  'soft-delete-motorist-by-id': 'exclusão suave de motorista por ID',
  'update-motorist': 'atualizar motorista',
  'update-motorist-attachment': 'atualizar anexo de motorista',
  'update-motorist-attachments-base-64': 'atualizar anexos de motorista em base 64',
  'get-connection-status': 'obter status da conexão',
  'create-owner-by-motorist': 'criar proprietário por motorista',
  'create-owner': 'criar proprietário',
  'delete-owner': 'excluir proprietário',
  'detach-trailer-owner': 'desanexar proprietário do reboque',
  'detach-vehicle-owner': 'desanexar proprietário do veículo',
  'find-owner': 'encontrar proprietário',
  'list-owner': 'listar proprietários',
  'update-owner': 'atualizar proprietário',
  'get-person-by-cnpj': 'obter pessoa por CNPJ',
  'list-states': 'listar estados',
  'create-tracker-devices': 'criar dispositivos de rastreamento',
  'delete-tracker-devices': 'excluir dispositivos de rastreamento',
  'find-by-id-tracker-devices': 'encontrar dispositivos de rastreamento por ID',
  'list-tracker-devices': 'listar dispositivos de rastreamento',
  'update-tracker-devices': 'atualizar dispositivos de rastreamento',
  'create-trailer': 'criar reboque',
  'delete-trailer': 'excluir reboque',
  'find-trailer-by-id': 'encontrar reboque por ID',
  'find-trailer': 'encontrar reboque',
  'list-trailer': 'listar reboques',
  'update-trailer': 'atualizar reboque',
  'create-vehicle-body': 'criar carroceria de veículo',
  'list-vehicle-body': 'listar carrocerias de veículos',
  'search-vehicle-body': 'procurar carroceria de veículo',
  'create-vehicle-category': 'criar categoria de veículo',
  'list-vehicle-category': 'listar categorias de veículos',
  'search-vehicle-category': 'procurar categoria de veículo',
  'attach-trailer': 'anexar reboque',
  'create-vehicle': 'criar veículo',
  'delete-vehicle': 'excluir veículo',
  'detach-trailer': 'desanexar reboque',
  'find-vehicle-by-id': 'encontrar veículo por ID',
  'find-vehicle': 'encontrar veículo',
  'list-vehicle': 'listar veículos',
  'search-vehicle-by-license-plate': 'procurar veículo pela placa',
  'update-vehicle': 'atualizar veículo',
  'delete-black-list-by-document': 'excluir lista negra por documento',
  'find-city-by-ibge-id': 'encontrar cidade por ID do IBGE',
  'list-cities-by-geo': 'listar cidades por geografia',
  'list-cities-by-name': 'listar cidades por nome',
  'list-cities-by-state-ibge-id': 'listar cidades por ID de estado do IBGE',
  'update-city': 'atualizar cidade',
  'create-client-feedbacks': 'criar feedbacks de cliente',
  'delete-client-feedbacks': 'excluir feedbacks de cliente',
  'find-client-feedbacks-by-freight': 'encontrar feedbacks de cliente por frete',
  'list-client-feedbacks': 'listar feedbacks de cliente',
  'create-client-monthly-nps': 'criar NPS mensal de cliente',
  'delete-client-monthly-nps': 'excluir NPS mensal de cliente',
  'find-client-monthly-nps': 'encontrar NPS mensal de cliente',
  'list-client-monthly-nps': 'listar NPS mensal de cliente',
  'create-document-handler-addresses': 'criar endereços do manipulador de documentos',
  'create-document-handler-companies': 'criar empresas do manipulador de documentos',
  'delete-document-handler-companies': 'excluir empresas do manipulador de documentos',
  'find-document-handler-companies': 'encontrar empresas do manipulador de documentos',
  'list-document-handler-companies': 'listar empresas do manipulador de documentos',
  'update-document-handler-companies': 'atualizar empresas do manipulador de documentos',
  'get-crlv-informations': 'obter informações do CRLV',
  'create-document-handler-cte': 'criar CTE do manipulador de documentos',
  'create-document-handler-cte-event': 'criar evento de CTE do manipulador de documentos',
  'generate-pdf-document-handler-cte': 'gerar PDF do CTE do manipulador de documentos',
  'create-freight-contract': 'criar contrato de frete',
  'create-document-handler-mdfe': 'criar MDF-e do manipulador de documentos',
  'create-document-handler-mdfe-event': 'criar evento de MDF-e do manipulador de documentos',
  'generate-pdf-document-handler-mdfe': 'gerar PDF do MDF-e do manipulador de documentos',
  'create-document-handler-people': 'criar pessoas do manipulador de documentos',
  'delete-document-handler-people': 'excluir pessoas do manipulador de documentos',
  'find-document-handler-people': 'encontrar pessoas do manipulador de documentos',
  'list-document-handler-people': 'listar pessoas do manipulador de documentos',
  'list-professions-document-handler-people': 'listar profissões do manipulador de documentos',
  'update-document-handler-people': 'atualizar pessoas do manipulador de documentos',
  'get-pis-informations': 'obter informações do PIS',
  'send-register-antt-owner': 'enviar registro de proprietário para ANTT',
  'send-register-motorist': 'enviar registro de motorista',
  'send-register-trailer-owner': 'enviar registro de proprietário de reboque',
  'send-register-vehicle-owner': 'enviar registro de proprietário de veículo',
  'create-document-handler-vehicles': 'criar veículos do manipulador de documentos',
  'delete-document-handler-vehicles': 'excluir veículos do manipulador de documentos',
  'find-document-handler-vehicles': 'encontrar veículos do manipulador de documentos',
  'list-brands-document-handler-vehicle': 'listar marcas de veículos do manipulador de documentos',
  'list-categories-document-handler-vehicle': 'listar categorias de veículos do manipulador de documentos',
  'list-groups-document-handler-vehicle': 'listar grupos de veículos do manipulador de documentos',
  'list-document-handler-vehicles': 'listar veículos do manipulador de documentos',
  'check-ciot-status': 'verificar status do CIOT',
  'create-ciot': 'criar CIOT',
  'find-ciot': 'encontrar CIOT',
  'list-ciots': 'listar CIOTs',
  'create-freight-expense': 'criar despesa de frete',
  'delete-freight-expense': 'excluir despesa de frete',
  'find-freight-expense': 'encontrar despesa de frete',
  'list-freight-expense': 'listar despesas de frete',
  'update-freight-expense': 'atualizar despesa de frete',
  'create-freight-expenses-history': 'criar histórico de despesas de frete',
  'delete-freight-expenses-history': 'excluir histórico de despesas de frete',
  'find-freight-expense-history': 'encontrar histórico de despesas de frete',
  'list-freight-expenses-history': 'listar históricos de despesas de frete',
  'update-freight-expenses-history': 'atualizar histórico de despesas de frete',
  'create-route-points': 'criar pontos de rota',
  'find-route-points': 'encontrar pontos de rota',
  'create-freight-vehicle-tags': 'criar tags de veículos de frete',
  'find-toll': 'encontrar pedágio',
  'find-toll-voucher': 'encontrar comprovante de pedágio',
  'get-freights-tolls': 'obter pedágios de fretes',
  'get-toll-value-by-freight-id': 'obter valor de pedágio por ID de frete',
  'get-tolls-tags-transporters': 'obter tags de pedágios de transportadores',
  'create-motorist-check-in': 'criar check-in de motorista',
  'get-last-motorists-checkins': 'obter últimos check-ins de motoristas',
  'list-all-plank-checkins': 'listar todos os check-ins de prancha',
  'create-bulk-sending-waypoints': 'criar envio em massa de waypoints',
  'create-motorist-waypoints': 'criar waypoints de motorista',
  'list-motorist-waypoints': 'listar waypoints de motorista',
  'create-nps-motorist': 'criar NPS de motorista',
  'delete-nps-motorist': 'excluir NPS de motorista',
  'find-nps-motorist': 'encontrar NPS de motorista',
  'list-nps-motorist': 'listar NPS de motorista',
  'validate-motorist-informations-by-antt': 'validar informações do motorista pela ANTT',
  'approve-expense-notification': 'aprovar notificação de despesa',
  'risk-analysis-notification': 'notificação de análise de risco',
  'change-freight-status': 'alterar status do frete',
  'check-pendencies-freight': 'verificar pendências de frete',
  'count-freights': 'contar fretes',
  'create-freight': 'criar frete',
  'delete-freight': 'excluir frete',
  'find-freight-by-id': 'encontrar frete por ID',
  'find-freight-by-vehicle-id': 'encontrar frete por ID de veículo',
  'find-freight': 'encontrar frete',
  'freight-calculation-history': 'histórico de cálculo de frete',
  'list-compatible-motorist': 'listar motoristas compatíveis',
  'list-freight': 'listar fretes',
  'list-freight-in-progress': 'listar fretes em andamento',
  'route-analysys': 'análise de rota',
  'update-freight': 'atualizar frete',
  'create-quotation': 'criar cotação',
  'delete-quotation': 'excluir cotação',
  'find-quotation-by-id': 'encontrar cotação por ID',
  'find-quotation': 'encontrar cotação',
  'list-quotation': 'listar cotações',
  'update-quotation': 'atualizar cotação',
  'create-route-prices': 'criar preços de rota',
  'delete-route-prices': 'excluir preços de rota',
  'find-route-prices': 'encontrar preços de rota',
  'get-antt-calculator': 'obter calculadora da ANTT',
  'list-route-prices': 'listar preços de rota',
  'update-route-prices': 'atualizar preços de rota',
  'change-status-checklist-risk-analysis': 'alterar status da checklist de análise de risco',
  'create-checklist-risk-analysis-attachment': 'criar anexo de checklist de análise de risco',
  'create-checklist-item': 'criar item de checklist',
  'create-checklist-risk-analysis': 'criar checklist de análise de risco',
  'delete-checklist': 'excluir checklist',
  'find-checklist-item-by-checklist-id': 'encontrar item de checklist por ID de checklist',
  'list-checklist-by-risk-analysis-id': 'listar checklists por ID de análise de risco',
  'create-cost-risk-analysis': 'criar análise de custo de risco',
  'delete-cost-risk-analysis': 'excluir análise de custo de risco',
  'find-by-id-cost-risk-analysis': 'encontrar análise de custo de risco por ID',
  'find-cost-risk-analysis-by-risk-analysis-id':
    'encontrar análise de custo de risco por ID de análise de risco',
  'list-cost-risk-analysis': 'listar análises de custo de risco',
  'update-cost-risk-analysis': 'atualizar análise de custo de risco',
  'change-status-risk-analysis': 'alterar status da análise de risco',
  'create-risk-analysis': 'criar análise de risco',
  'delete-risk-analysis': 'excluir análise de risco',
  'find-by-options-risk-analysis': 'encontrar análise de risco por opções',
  'find-risk-analysis-by-id': 'encontrar análise de risco por ID',
  'list-risk-analysis': 'listar análises de risco',
  'update-risk-analysis': 'atualizar análise de risco',
  'find-risk-analysis-history': 'encontrar histórico de análise de risco',
  'list-buonny-motorist-check': 'listar verificação de motorista Buonny',
  'check-risk': 'verificar risco',
  'insert-professional': 'inserir profissional',
  'list-vehicle-fabricators-buonny': 'listar fabricantes de veículos Buonny',
  'list-vehicle-models-buonny': 'listar modelos de veículos Buonny',
  'create-trailer-attachments': 'criar anexos de reboque',
  'update-trailer-attachment': 'atualizar anexo de reboque',
  'create-user': 'criar usuário',
  'delete-user': 'excluir usuário',
  'find-user': 'encontrar usuário',
  'list-user': 'listar usuários',
  'list-user-by-role': 'listar usuários por papel',
  'update-user': 'atualizar usuário',
  'create-vehicle-attachments': 'criar anexos de veículo',
  'update-vehicle-attachment': 'atualizar anexo de veículo',
  'create-client-business-group': 'criar grupo empresarial de cliente',
  'delete-client-business-group': 'excluir grupo empresarial de cliente',
  'list-client-business-group': 'listar grupos empresariais de cliente',
  'update-client-business-group': 'atualizar grupo empresarial de cliente',
  'list-client-business-group-segment': 'listar segmentos de grupos empresariais de cliente',
  'create-freight-expense-attachment': 'criar anexo de despesa de frete',
  'delete-freight-expense-attachment': 'excluir anexo de despesa de frete',
  'list-freight-expense-attachment': 'listar anexos de despesa de frete',
  'create-cargo': 'criar carga',
  'delete-cargo': 'excluir carga',
  'list-cargo': 'listar cargas',
  'update-cargo': 'atualizar carga',
  'calc-cte-value': 'calcular valor de CTE',
  'change-cte-status-to-rebilling': 'alterar status do CTE para reemissão',
  'chenge-freight-related-to-cte': 'alterar frete relacionado ao CTE',
  'daily-closure-expectation': 'expectativa de fechamento diário',
  'find-ctes': 'encontrar CTEs',
  'list-ctes': 'listar CTEs',
  'verify-shared-ctes': 'verificar CTEs compartilhados',
  'create-freight-attachment': 'criar anexo de frete',
  'delete-freight-attachment': 'excluir anexo de frete',
  'list-freight-attachment': 'listar anexos de frete',
  'update-freight-attachment': 'atualizar anexo de frete',
  'create-freight-status-history': 'criar histórico de status de frete',
  'list-freight-status-history': 'listar históricos de status de frete',
  'create-interaction-timeline': 'criar linha do tempo de interações',
  'delete-interaction-timeline': 'excluir linha do tempo de interações',
  'find-by-id-interactions-timeline': 'encontrar linha do tempo de interações por ID',
  'list-interaction-timeline': 'listar linhas do tempo de interações',
  'update-interactions-timeline': 'atualizar linha do tempo de interações',
  'list-mdfes': 'listar MDF-e',
  'create-monitoring-occurrences': 'criar ocorrências de monitoramento',
  'find-monitoring-occurrences': 'encontrar ocorrências de monitoramento',
  'list-monitoring-occurrences': 'listar ocorrências de monitoramento',
  'list-monitoring-occurrences-reasons': 'listar razões de ocorrências de monitoramento',
  'update-monitoring-occurrences': 'atualizar ocorrências de monitoramento',
  'link-motorist-to-freight': 'vincular motorista ao frete',
  'unlink-motorist-to-freight': 'desvincular motorista do frete',
  'create-nfes': 'criar NF-e',
  'list-nfes': 'listar NF-e',
  'notify-operator-if-freight-without-motorist': 'notificar operador se frete sem motorista',
  'create-rate-freight': 'criar tarifa de frete',
  'delete-rate-freight': 'excluir tarifa de frete',
  'freight-report': 'relatório de frete',
  'attach-stub-nfes': 'anexar NF-e stub',
  'create-stubs': 'criar stubs',
  'delete-stubs': 'excluir stubs',
  'list-formatted-stubs-by-freight': 'listar stubs formatados por frete',
  'list-stubs': 'listar stubs',
  'create-role': 'criar cargo',
  'force-freight-status': 'forçar status do frete',
  'force-status-risk-analysis': 'forçar status da analise de risco',
  'delete-role': 'excluir cargo',
  'update-authorization-files': 'atualizar arquivos de autorização',
  'validate-cnpj': 'validar CNPJ',
  'validate-cpf': 'validar CPF',
  'validate-drivers-license': 'validar carteira de motorista',
  'get-directions-to-polylines-map': 'obter direções para mapa de polilinhas',
  'generate-freight-contract-pdf': 'gerar PDF do contrato de frete',
  'plain-route': 'rota simples',
  'calculate-freight-distance': 'calcular distância de frete',
  'create-map-target': 'criar alvo de mapa',
  'find-freight-by-id-without-auth': 'encontrar frete por ID sem autenticação',
  'list-freight-to-operation': 'listar fretes para operação',
  'predict-service-price': 'prever preço do serviço',
  'predict-agreed-price': 'prever preço acordado',
  'convert-pdf-to-image': 'converter PDF para imagem',
  'invert-vehicle-registrations': 'inverter registros de veículo',

  // Frontend
  spotHub: 'SpotHub',
  'analyze-stub': 'Analisar Canhoto',
  'force-approve-risk-analysis': 'Forçar aprovação da análise de risco',
  'show-generate-report-seller': 'Mostrar botão de gerar relatório de vendas',
  'show-generate-complete-report-ctes': 'Mostrar botão de gerar relatório completo de CTEs',
  'show-filter-seller': 'Mostrar filtro de vendedor',
  'show-reprove-check-list-risk-analysis': 'Mostrar botão de reprovar checklist de análise de risco',
  'show-generate-report-ctes': 'Mostrar botão de gerar relatório de CTEs',
  'show-generate-report-extra-expenses': 'Mostrar botão de gerar relatório de despesas extras',
  'show-generate-report-motorists-feedbacks': 'Mostrar botão de gerar relatório de feedbacks de motoristas',
  'show-cancel-freight-expense': 'Mostrar botão de cancelar despesa de frete',
  'show-pending-freight-expense': 'Mostrar botão de pendente de despesa de frete',
  'show-save-attachments-freight-expense': 'Mostrar botão de salvar anexos de despesa de frete',
  'show-finish-freight-expense': 'Mostrar botão de finalizar despesa de frete',
  'show-handle-paid-freight-expense': 'Mostrar botão de tratamento de pagamento de despesa de frete',
  'show-request-proof-freight-expense': 'Mostrar botão de requerimento de prova de despesa de frete',
  'show-reject-freight-expense': 'Mostrar botão de rejeitar despesa de frete',
  'show-force-status': 'Mostrar botão de forçar status',
  'show-change-status-quotation': 'Mostrar botão de alterar status da cotação',
  'show-force-disable-quotation': 'Mostrar botão de forçar desabilitar cotação',
  'show-back-status-quotation': 'Mostrar botão de voltar status da cotação',
  'show-next-status-quotation': 'Mostrar botão de proximo status da cotação',
  'show-delete-tracker-device': 'Mostrar botão de deletar dispositivo de rastreamento',
  'show-delete-attachment': 'Mostrar botão de deletar anexo',
  'show-create-receivable-bill': 'Mostrar botão de criar conta a receber',
  'create-observation-financial-title': 'Mostrar botão de criar observação de título financeiro',
  'show-evaluate-loading-request': 'Mostrar botão de aprovar/reprovar solicitação de carregamento',
  'show-upload-loading-request-receipt':
    'Motrar botão de anexo de recibo de pagamento da solicitação de carregamento',
  'show-evaluate-advance-request': 'Mostrar botão de aprovar/reprovar solicitação de adiantamento',
  'show-upload-advance-request-receipt':
    'Motrar botão de anexo de recibo de pagamento da solicitação de adiantamento',
  'show-evaluate-balance-request': 'Mostrar botão de aprovar/reprovar solicitação de saldo',
  'show-upload-balance-request-receipt':
    'Motrar botão de anexo de recibo de pagamento da solicitação de saldo',
  'show-evaluate-cte-issuance-request': 'Mostrar botão de aprovar/reprovar solicitação de emissão de CTe',
  'show-upload-loading-request-cf':
    'Mostrar botão de anexo de contrato de frete da solicitação de carregamento',
}

export default pt
