import { ChakraProvider } from '@chakra-ui/react'
import { QueryClientProvider } from 'react-query'
import { BrowserRouter } from 'react-router-dom'
import { EnvFlag } from './components/EnvironmentFlag'
import ModalVersion from './components/ModalVersion/ModalVersion'
import { SendFeedbackButton } from './components/SendFeedbackButton'
import { queryClient } from './config/react-query'
import { theme } from './config/theme'
import { AppProvider } from './contexts'
import { Routes } from './routes'

export function App(): JSX.Element {
  return (
    <BrowserRouter>
      <ChakraProvider theme={theme}>
        <EnvFlag>
          <QueryClientProvider client={queryClient}>
            <ModalVersion />
            <AppProvider>
              <Routes />
            </AppProvider>
          </QueryClientProvider>
        </EnvFlag>

        <SendFeedbackButton />
      </ChakraProvider>
    </BrowserRouter>
  )
}
